import React from "react"

import Layout from "gatsby-theme-blog/src/components/layout"
import SEO from "gatsby-theme-blog/src/components/seo"
import Footer from "gatsby-theme-blog/src/components/home-footer"
import { graphql } from "gatsby"

const About = ({ location, data }) => (
  <Layout location={location} title={data.site.siteMetadata.title}>
    <main>
      <SEO title="About" description="hnishi's about" />
      <h1>このサイトについて </h1>
      <p>hnishi のブログです。</p>
      <h2>hnishi について </h2>
      <p>高校は工業高校で土木の勉強をしていました。</p>
      <p>大学は理学部で生物の勉強をしていました。</p>
      <p>大学院は生物物理の研究をして博士学位を取得しました。</p>
      <p>今はソフトウェアエンジニアをしています。</p>
      <p>最近の業務ではデータ分析をしていることが多いです。</p>
      <h2>Amazon の広告配信について</h2>
      <p>
        hnishi
        は、Amazon.co.jpを宣伝しリンクすることによってサイトが紹介料を獲得できる手段を提供することを目的に設定されたアフィリエイトプログラムである、Amazonアソシエイト・プログラムの参加者です。
      </p>
    </main>
    <Footer socialLinks={data.site.siteMetadata.social} />
  </Layout>
)

export default About

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        social {
          name
          url
        }
      }
    }
  }
`
